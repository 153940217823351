@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.articles {
  $block-name: &;

  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;

  @include mq($to: md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__header {
    display: flex;
    margin-bottom: 34px;
    justify-content: space-between;

    @include mq($to: lg) {
      // justify-content: center;
    }

    @include mq($to: md) {
      margin-bottom: 25px;
    }
  }

  &__header-left {
  }

  &__header-text {
    margin-top: 20px;
  }

  &__header-right {
    margin-left: 40px;
    align-self: flex-end;

    @include mq($to: lg) {
      display: none;
    }
  }

  &__control {
    margin-top: 30px;
    display: none;

    @include mq($to: lg) {
      display: block;
    }
  }

  //   #{$block-name} {
  //   }
}
